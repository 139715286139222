import * as React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import './global.scss';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faShoppingBag, faCircle, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
//fa-graduation, fa-puzzle-piece, shopping-bag, fa-circle 

class IndexPage extends React.Component {
  public render() {
    return (
      <>
        <Header />

        <div className="home">
          <div className="hero">
            <section className="section hero-big background-gradient">
              <div className="hero-no-image padding-top-XL container">
                <div className="col-sm-7 col-xs-12 mark-text">
                  <p className="margin-top-M text-L">DirekTek is a channel platform connecting leading imaging, optical, home &amp; office brands with forward-thinking businesses. We&#8217;ll help you curate a cutting-edge range of products and fulfil your in-store, B2B, dropship and click &amp; collect orders.</p>
                  <div className="margin-top-XL">
                    <a href="/about" className="margin-top-M text-L text-left btn btn-default squared">Learn more</a>
                    <a href="/contact" className="margin-top-M text-L text-left btn btn-default squared" style={{ marginLeft: 10 }}>Speak with a product specialist</a>
                  </div>
                </div>
                <div className="col-sm-5 col-xs-12">
                  <img className="prod-img prod-img-f img-responsive" src="images/platformhero.png" />
                </div>
              </div>
            </section>
          </div>
          <section className="m-content" id="brands">
            <div className="container partners">
              <h2 className="padding-bottom-L">We import and distribute world-leading brands and products</h2>
              <div className="col-md-10 col-md-offset-1 col-sm-12 text-L padding-bottom-XL text-center">
                <div className="row">
                  <div className="col-xs-3 padding-M">
                    <div className="category-grid">
                      <a href="browse/pim_594.html" className='disabled-link'><img src="images/cats/photo_red.png" />
                        <p>Photo</p></a>
                    </div>
                  </div>
                  <div className="col-xs-3 padding-M" style={{ background: "#f5f5f5" }}>
                    <div className="category-grid">
                      <a href="browse/pim_38.html" className='disabled-link'><img src="images/cats/video_red.png" />
                        <p>Video</p></a>
                    </div>
                  </div>
                  <div className="col-xs-3 padding-M">
                    <div className="category-grid">
                      <a href="browse/pim_770.html" className='disabled-link'><img src="images/cats/optics_red.png" />
                        <p>Optics</p></a>
                    </div>
                  </div>
                  <div className="col-xs-3 padding-M" style={{ background: "#f5f5f5" }}>
                    <div className="category-grid">
                      <a href="browse/pim_724.html" className='disabled-link'><img src="images/cats/sound_vision_red.png" />
                        <p>Sound &amp; Vision</p></a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-3 padding-M" style={{ background: "#f5f5f5" }}>
                    <div className="category-grid">
                      <a href="browse/pim_31.html" className='disabled-link'><img src="images/cats/brackets_mounts_red.png" />
                        <p>Brackets &amp; Mounts</p></a>
                    </div>
                  </div>
                  <div className="col-xs-3 padding-M">
                    <div className="category-grid">
                      <a href="browse/pim_574.html" className='disabled-link'><img src="images/cats/smart_tech_red.png" />
                        <p>Smart Tech</p></a>
                    </div>
                  </div>
                  <div className="col-xs-3 padding-M" style={{ background: "#f5f5f5" }}>
                    <div className="category-grid">
                      <a href="browse/pim_727.html" className="disabled-link"><img src="images/cats/home_office_red.png" />
                        <p>Home &amp; Office</p></a>
                    </div>
                  </div>
                  <div className="col-xs-3 padding-M">
                    <div className="category-grid">
                      <a href="browse/pim_598.html" className='disabled-link'><img src="images/cats/digital_storage_red.png" />
                        <p>Digital Storage</p></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 m-content1 text-center">
                <div className="partners-list">
                  <a href="http://www.canon.co.uk/" target="_blank" >
                    <img alt="Canon" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/canon.gif" />
                  </a>
                  <a href="https://www.sony.co.uk/" target="_blank">
                    <img alt="Sony" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/sony.png" />
                  </a>
                  <a href="http://www.samsung.com/uk/home/" target="_blank">
                    <img alt="Samsung" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/samsung.jpg" />
                  </a>
                  <a href="http://www.praktica.de" target="_blank">
                    <img alt="Praktica" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/praktica.jpg" />
                  </a>
                  <a href="http://www.kodakpixpro.com/Europe/" target="_blank">
                    <img alt="Kodak" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/kodak.png" />
                  </a>
                  <a href="http://www.olympus.co.uk/" target="_blank">
                    <img alt="Olympus" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/olympus.png" />
                  </a>
                  <a href="http://www.ricoh.co.uk/" target="_blank">
                    <img alt="Ricoh" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/ricoh.png" />
                  </a>
                  <a href="http://uk.jvc.com/" target="_blank">
                    <img alt="JVC" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/jvc.gif" />
                  </a>
                  <a href="http://www.fujifilm.com/" target="_blank">
                    <img alt="Fujifilm" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/fuji.gif" />
                  </a>
                  <a href="http://www.proper.co.uk/" target="_blank">
                    <img alt="Proper" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/proper.png" />
                  </a>
                </div>
              </div>
              <div className="col-xs-12 padding-top-M">
                <a className="btn btn-success squared text-L" href="brands">Explore our brands</a>
              </div>
            </div>
          </section>

          <section className="m-content background-grey" id="customers">
            <div className="dropship container">
              <h2 className="padding-bottom-L">We power exceptional retail experiences for forward-thinking businesses</h2>
              <div className="row text-left">
                <div className="col-sm-12 col-md-4 padding-bottom-S">
                  <span className="fa-stack fa-2x">
                  <FontAwesomeIcon
                      className="fa fa-stack-2x text-red2"
                      color='transparent'
                      fixedWidth
                      transform='shrink-12'
                      icon={faCircle}/>
                    <FontAwesomeIcon
                      className="fa fa-stack-1x"
                      inverse
                      fixedWidth
                      pull='left'
                      transform='right-3'
                      icon={faShoppingBag}/>
                      </span>
                  <h3 className="text-red1">Mass Retailers</h3>
                  <p className="text-M">Supermarkets, e-tailers, marketplaces, and mail order houses can rapidly integrate a wide range of products with greater availability, quicker delivery options, and proactive customer service.</p>
                </div>
                <div className="col-sm-12 col-md-4 padding-bottom-S">
                  <span className="fa-stack fa-2x">
                    <FontAwesomeIcon
                      className="fa fa-stack-2x text-red2"
                      color='transparent'
                      fixedWidth
                      icon={faCircle}/>
                    <FontAwesomeIcon
                      className="fa fa-stack-1x"
                      inverse
                      fixedWidth
                      pull='left'
                      transform='right-3'
                      icon={faGraduationCap}/>
                  </span>
                  <h3 className="text-red1">Corporates, Government &amp; Education</h3>
                  <p className="text-M">Our dedicated account managers are on hand to expertly curate product quotations, help you identify the right solution and support for tenders or special bids to meet your requirements.</p>
                </div>
                <div className="col-sm-12 col-md-4 padding-bottom-S">
                  <span className="fa-stack fa-2x">
                  <FontAwesomeIcon
                      className="fa fa-stack-2x text-red2"
                      fixedWidth

                      color='transparent'
                      icon={faCircle}
                      />
                    <FontAwesomeIcon
                      className="fa fa-stack-1x"
                      inverse
                      fixedWidth

                      pull='left'
                      transform='right-4'
                      icon={faPuzzlePiece}/>
                  </span>
                  <h3 className="text-red1">Specialists &amp; Independents</h3>
                  <p className="text-M">We appreciate the true value of independent and specialist merchants. With one channel partner - you can access credit, easy ordering, utilise our marketing services and unique promotions.</p>
                </div>
              </div>
              <div className="row">
                <div className="retailers-list col-md-4 hidden-xs hidden-sm">
                  <img alt="Dixons" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/dixons.png" />
                  <img alt="Argos" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/argos.png" />
                  <img alt="Tesco" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/tesco.png" />
                  <img alt="Shop Direct" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/sdg.png" />
                  <img alt="JD Williams" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/jdw.png" />
                  <img alt="Amazon" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/amazon.png" />
                </div>
                <div className="retailers-list col-md-4 hidden-xs hidden-sm">
                  <img alt="Dabs" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/dabs.gif" />
                  <img alt="Insight" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/insight.svg" />
                  <img alt="Ebuyer" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/ebuyer.png" />
                  <img alt="Office Depot" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/officedepot.png" />
                  <img alt="Misco" className="retailers-logo" src="images/ccs.svg" />
                  <img alt="Findel" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/findel.png" />
                </div>
                <div className="retailers-list col-md-4 hidden-xs hidden-sm">
                  <img alt="Wex" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/wex.png" />
                  <img alt="Ideal Shopping" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/idealshopping.png" />
                  <img alt="Jigsaw" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/jigsaw.png" />
                  <img alt="Jessops" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/jessops.png" />
                  <img alt="Camulet" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/calumet.png" />
                  <img alt="Grassroots" className="retailers-logo" src="https://assets.direktek.co.uk/images/home/retailers/grassroots.png" />
                </div>
              </div>
            </div>
          </section>

          <div className="container padding-top-XL padding-bottom-XL text-center">
            <i className="fa fa-twitter fa-5x" style={{ color: "#1DA1F2" }} aria-hidden="true"></i>
            <h2>Ready to get started?</h2>
            <h4 className="margin-top-L">
              DirekTek is a leading trade-only distributor with unique dropship capabilities. Our industry expertise and agile infrastructure allow us to
              develop unique propositions for customers.
              </h4>
            <a className="btn btn-success squared text-L margin-top-L" href="/open">Open an account today!</a>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default IndexPage;
